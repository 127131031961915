import React, { useEffect, useState } from 'react';
import { render } from "react-dom";
import { Router } from "@reach/router";
import {ContentfulClient, Contentfully} from "contentfully";
import { formatContentfullyData } from '../assets/scripts/helpers';
import Page from "../templates/page";

const PreviewPage = props => {
    let clientOptions = {
            spaceId: 'nw29p03vv8ee',
            accessToken: 'dW_aZoa5vHLNIHgmQhncp7tNRPtrhhJwZ54yrj9rWDc',
            preview: true
        };
    const [pageData, setPageData] = useState(null);

    switch(props.location.search.split('?')[1]) {
        case 'development':
                clientOptions.environmentId = 'development';
                clientOptions.accessToken = '_ZkfLovEXZ0BOPT2Z-do-BXvQ36Rnk61sFl71Zt5LOg';
            break;
        case 'staging':
                clientOptions.environmentId = 'staging';
                clientOptions.accessToken = 'KcQSGPpGZsleP8rjs6CH6gtGmZ1ej7qrI9wMszbeExk';
            break;
        default:
    }

    const contentfulClient = new ContentfulClient(clientOptions);
    const contentfully = new Contentfully(contentfulClient);

    useEffect(() => {
        let path = props['*']

        if (typeof window !== 'undefined') {
            if (!window.location.hostname.includes('.l1.')) {
                path = '404';
            }
        }

        if (!pageData) {
            (async () => {
                const response = await contentfully.getModels({
                    'content_type': 'page',
                    'fields.pathname': '/' + path
                });

                if (response.items[0]) {
                    formatContentfullyData(response.items[0]);
                    setPageData(response.items[0]);
                }
            })();
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {pageData &&
                <Page location={window !== 'undefined' && window.location} pageContext={{pageData: pageData}} />
            }
        </div>

    );
}

export default PreviewPage;

if (typeof document !== 'undefined') {
    render(
        <Router>
            <PreviewPage path="rescifos-preview" />
        </Router>,
        document.getElementById('___gatsby')
    )
}
